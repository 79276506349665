import axios from 'axios';

function redirectToMicrosoft() {
    let nonce = Math.random().toString(16).substring(2, 13);
    let redirect = `${process.env.VUE_APP_OAUTH2_URL}/authorize?`;

    redirect += `client_id=${process.env.VUE_APP_OAUTH2_CLIENT_ID}&`;
    redirect += `redirect_uri=${encodeURIComponent(process.env.VUE_APP_OAUTH2_REDIRECT_URL)}&`;
    redirect += `scope=User.Read&`;
    redirect += `response_type=token&`;
    redirect += `response_mode=fragment&`;
    redirect += `nonce=${nonce}`;
    window.location.href = redirect;
}
function saveProfileSettings(jwtDecode: any) {
    let profile = {
        name: jwtDecode.name,
        family_name: jwtDecode.family_name,
        given_name: jwtDecode.given_name,
    }
    localStorage.profile = JSON.stringify(profile);

}

// function getFormUrlencoded(data: any) {
//     const params: string[] = data;

//     return Object.entries(params)
//         .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
//         .join('&');
// }

function decodeJwtToken(access_token: string) {
    let base64Url = access_token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export default {
    getHeader() {
        let token = JSON.parse(localStorage.auth);
        
        return {
            headers: {
                Accept: "*/*",
                Connection: "keep-alive",
                Authorization: `Bearer ${token.access_token}`,
            },
        }
    },
    async init() {
        if (process.env.NODE_ENV !== 'production') {
            localStorage.auth = JSON.stringify({
                access_token: process.env.VUE_APP_DEV_JWT,
                expires_in: "10000",
            });
        }

        if (!localStorage.auth) {
            let hashParams = new URLSearchParams(window.location.hash.substring(1));
            let token = hashParams.get("access_token");

            if (token) {
                localStorage.auth = JSON.stringify({
                    access_token: token,
                    expires_in: hashParams.get("expires_in")
                });

                //clear hash params in the browser url
                window.history.pushState('', document.title, `${window.location.origin}${window.location.pathname}`);
            } else {
                localStorage.clear();
                redirectToMicrosoft();

                return;
            }
        }

        let authJson = JSON.parse(localStorage.auth);
        let jwtDecode = decodeJwtToken(authJson.access_token);

        saveProfileSettings(jwtDecode)

        let time = jwtDecode.exp - Math.round((new Date()).getTime() / 1000);
        if (time <= 100) {
            localStorage.clear();
            redirectToMicrosoft();
            return;
        }

        if(!localStorage.user_image){
            axios.get("https://graph.microsoft.com/v1.0/me/photo/$value",
                {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${authJson.access_token}`,
                    },
                }
            ).then((resp) => {
                let reader = new window.FileReader();
                reader.readAsDataURL(resp.data); 
                reader.onload = () => {
                    let imageDataUrl = reader.result;
                    
                    localStorage.user_image = imageDataUrl;
                }
            });
        }
    }
};
