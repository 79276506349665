
import { defineComponent } from "vue";
import axios from "axios";
import auth from "../auth";

export default defineComponent({
    data() {
        return {
            next_possible_order_date: new Date(),
            order_amount: 1,
        };
    },
    methods: {
        findNextOrderDate() {
            //let this.next_possible_order_date = Date = new Date();
            this.next_possible_order_date.setDate(
                this.next_possible_order_date.getDate() + 1
            );

            //Wochentage der Bestellungen als array So 0, Mo 1, Di 2, ...
            const POSS_NEXT_ORDER_DATE = (
                process.env.VUE_APP_POSS_NEXT_ORDER_DATE as string
            ).split(",");

            //Spätester Zeitpunkt der Bestellung ist der Vortag 1100Uhr
            if (this.next_possible_order_date.getHours() >= 11) {
                this.next_possible_order_date.setDate(
                    this.next_possible_order_date.getDate() + 1
                );
            }

            //Es soll nur der nächstmögliche Tag angegeben werden.
            while (
                POSS_NEXT_ORDER_DATE.indexOf(
                    `${this.next_possible_order_date.getDay()}`
                ) == -1
            ) {
                this.next_possible_order_date.setDate(
                    this.next_possible_order_date.getDate() + 1
                );
            }

        },
        //manuelles Erhöhen der zu bestellenden Menge bis zu einem Max von 9
        increaseAmount() {
            if (this.order_amount < 9)
                this.order_amount = this.order_amount + 1;
        },
        //manuelles Verringern der zu bestellenden Menge bis zu einem Min von 1
        decreaseAmount() {
            if (this.order_amount > 1)
                this.order_amount = this.order_amount - 1;
        },
        //Übergabe der zu bestellenden Menge des Menüs zum nächstmöglichen Bestelldatum
        placeOrder() {
            let dateDe = this.next_possible_order_date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
            axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/api/v1/orders`,
                {
                    amount: this.order_amount,
                    delivery_date: dateDe.replaceAll(".", "-"),
                },
                auth.getHeader(),
            ).then(() => {
                window.alert(
                    `${this.order_amount} Meal${
                        this.order_amount > 1 ? "s" : ""
                    } for ${this.next_possible_order_date.toDateString()} have been ordered`
                );

                this.$emit("change-page", "ORDER_HISTORY")
            }).catch((e) => {
                window.alert(e);
            });
        },
    },
    //automatisches Ausführen von Code nach erfolgreichem Laden der gesamten Seite
    created() {
        this.findNextOrderDate();
    },
    computed: {
        url() {
            return process.env.VUE_APP_KANTINE;
        },
    },
});
