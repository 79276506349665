
import { defineComponent } from "vue";
import OrderPage from "./pages/OrderPage.vue";
import OrderHistory from "./pages/OrderHistory.vue";

export default defineComponent({
    components: {
        OrderPage,
        OrderHistory,
    },
    data() {
        return {
            toggleMenu: true,
            userImage: localStorage.user_image,
            profile: JSON.parse(localStorage.profile),
            selectedPage: "ORDER",
            pages: {
                "ORDER": "Order",
                "ORDER_HISTORY": "History",
            },
            animationTimeout: null as any,
            catchMeTexts: [
                "Catch me!",
                "Please, catch me!",
                "Click me, please!",
                "Come on!",
                "Don't give up!"
            ],
        };
    },
    methods: {
        changePosition() {
            let userBox = document.querySelector('.user-box') as HTMLDivElement;
            userBox.classList.add('disco');

            let catchMe = document.querySelector('.catch-me') as HTMLDivElement;
            catchMe.classList.remove("show");

            let x = (5 + (Math.random() * 90)).toFixed(0);
            let y = (5 + (Math.random() * 80)).toFixed(0);
            let r = (Math.random() * 360).toFixed(0);
            //console.log("x", x, "y", y);

            let target = document.querySelector('.track-box') as HTMLDivElement;
            target.setAttribute("style", `right: ${x}vw; top: ${y}vh;  rotate: ${r}deg;`);

            clearInterval(this.animationTimeout);
            this.animationTimeout = setInterval(() => {
                catchMe.classList.add("show");
                catchMe.innerHTML = this.catchMeTexts[
                    (Math.random() * (this.catchMeTexts.length -1)).toFixed(0) as any
                ];
                let x = (5 + (Math.random() * 90)).toFixed(0);
                let y = (5 + (Math.random() * 80)).toFixed(0);
                let r = (Math.random() * 360).toFixed(0);
                target.setAttribute("style", `right: ${x}vw; top: ${y}vh;  rotate: ${r}deg;`);
            }, 5000);
        },
        stopAnimation() {
            clearInterval(this.animationTimeout)

            let target = document.querySelector('.track-box') as HTMLDivElement;
            target.removeAttribute("style");

            let userBox = document.querySelector('.user-box') as HTMLDivElement;
            userBox.classList.remove('disco');

            let catchMe = document.querySelector('.catch-me') as HTMLDivElement;
            catchMe.classList.remove("show");
        }
    },
});
