
import { defineComponent } from "vue";
import axios from "axios";
import auth from "../auth";

export default defineComponent({
    data: () => ({
        orders: [] as any,
        links: {} as any,
        total: 0,
        count: 0,
    }),
    methods: {
        cancelOrder(order: any) {
            axios.delete(
                `${process.env.VUE_APP_BACKEND_URL}/api/v1/orders/${order.order_id}`,
                auth.getHeader(),
            ).then(() => {
                alert("Order canceled!");
                this.getOrders(`${process.env.VUE_APP_BACKEND_URL}/api/v1/orders`);
            }).catch((e) => {
                console.error(e);
                alert("Error");
            });
        },
        formatDate(dateStr: string) {
            let date = new Date(dateStr);
            return date.toLocaleDateString(
                'de-DE',
                { year: 'numeric', month: '2-digit', day: '2-digit' }
            );
        },
        getOrders(url: string) {
            axios.get(
                url,
                auth.getHeader(),
            ).then((resp) => {
                this.orders = resp.data._embedded.items;
                this.links = resp.data.links;
                this.total = resp.data.total;
                this.count += resp.data.count;
            }).catch((e) => {
                console.error(e);
            });
        },
        nextPage() {
            this.getOrders(
                this.links.next.href
            );
        },
        backPage() {
            this.getOrders(
                this.links.prev.href
            );
        }
    },
    mounted() {
        this.getOrders(`${process.env.VUE_APP_BACKEND_URL}/api/v1/orders`);
    },
    computed: {
        existNextPage() {
            return this.count < this.total;
        },
        existBackPage() {
            return this.count > this.orders.length;
        }
    }
})
